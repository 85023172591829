@import "../../constants.scss";

.directory-container {
  width: 80%;
  margin: 0 auto;

  @media (max-width: $firstBreak) {
    width: 80%;
  }

  @media (max-width: $secondBreak) {
    width: 90%;
  }

  h2 {
    text-align: center;
    color: $primaryGreen;
    margin: 75px 0 75px 0;
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: $thirdBreak) {
      font-size: 1.3rem;
    }
  }

  .filters {
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;

    @media (max-width: $thirdBreak) {
      font-size: 0.8rem;
    }

    p {
      margin-right: 10px;
    }
  }

  .list-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 4%;

    @media (max-width: $firstBreak) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5%;
    }

    @media (max-width: $secondBreak) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      margin: 0;
    }

    .item {
      color: $black;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item-content {
        margin-bottom: 10px;

        .icon-title-tor{
          display: flex;
          a {
            // color: $mediumGrey;
            color: inherit;
            font-size: 1rem;
            text-decoration: none;
            font-weight: bold;
            transition: all 0.2s ease-out;

            display: flex;
            align-items: center;

            @media (max-width: $thirdBreak) {
              font-size: 0.9rem;
            }

            &:hover {
              color: $primaryGreen;
            }

            svg {
              margin-right: 5px;
            }
          }

          .onionIcon {
            margin-left: auto;
            svg{
              width: 40px;
            }
          }
        }

        .social-media-icons {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
            padding-top: 1%;
        }

        p {
          margin-top: 15px;
          font-size: 0.9rem;
          line-height: 1.4;

          @media (max-width: $thirdBreak) {
            font-size: 0.8rem;
          }
        }
      } //item-content

      .separator {
        border-bottom: 1px solid lighten($grey, 25%);
      }
    } //item
  }
}

.directory-container-dark {
  
  .filters {
    color: white;

    .filter-button-container {
      button {
        color: black;
      }
    }
  }

  .list-container {
    .item {
      color: white;
      
      .item-content {
        a {
          color: white;
        }
      }
    }
  }
}
