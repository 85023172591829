$black: #262626;
$primaryGreen: #51B13E;
$grey : #8D8D8F;
$mediumGrey: #666;
$darkGreen: #1E7A44;
$lightGrey: ligthen($grey, 25%);

$darkBg: #292929;

$firstBreak: 1000px;
$secondBreak: 600px;
$thirdBreak: 500px;