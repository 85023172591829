@import "../../constants.scss";

.filter-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .filter-button {
        font-family: "Open Sans", sans-serif;
        font-size: 0.9rem;
        text-decoration: none;
        border: 1px solid $primaryGreen;
        border-radius: 20px;
        padding: 7.5px 15px;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;
        background: $primaryGreen;
        margin-right: 10px;
        outline: none;
        white-space: pre;

        @media (max-width: $thirdBreak) {
          font-size: 0.8rem;
        }

        &:hover {
          background: $darkGreen;
          color: white;
          border: 1px solid $darkGreen;
        }
    }
}

.filter-button-container-dark {
  background: #444444;
}