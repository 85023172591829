@import "../../constants.scss";

.newentry-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 75px;

  @media (max-width: $firstBreak) {
    width: 80%;
  }

  @media (max-width: $secondBreak) {
    width: 90%;
  }

  .entry-title {
    // width: 100%;
    // margin: 0 auto;
    font-size: 0.9rem;
    color: $black;
    padding: 20px 0 20px 15%;
    border-left: 4px solid $primaryGreen;

    @media (max-width: $secondBreak) {
      font-size: 0.9rem;
      border-left: none;
      padding-left: 0;
    }

    @media (max-width: $thirdBreak) {
        font-size: 0.8rem;
    }
  }

  .form-container {
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      border-left: 4px solid $primaryGreen;

      @media (max-width: $secondBreak) {
        border-left: none;
      }

      label {
        box-sizing: border-box;
        width: 100%;
        padding-left: 15%;
        color: $grey;
        font-size: 0.8rem;

        @media (max-width: $secondBreak) {
          padding-left: 0;
        }

        // @media (max-width: $thirdBreak) {
        //     font-size: 0.7rem;
        // }

        input,
        textarea {
          box-sizing: border-box;
          display: block;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 15px;
          height: 40px;
          border-radius: 5px;
          border: 1px solid $grey;
          padding: 0 10px;
          font-size: 0.8rem;
          font-family: inherit;

          &:focus {
            outline: none;
            border: 1px solid $primaryGreen;
          }

          &::placeholder {
            color: $grey;
          }
        }

        textarea {
          padding: 10px 7.5px;
          line-height: 1.5;
          height: 60px;
          margin-bottom: 7.5px;
        }

        .input-error-true {
          border: 1px solid red;
        }

        .select-container {
          display: flex;
          align-items: center;

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-sizing: border-box;
            width: 100%;
            display: block;
            background: white;
            margin-top: 5px;
            margin-bottom: 15px;
            font-size: 0.8rem;
            height: 40px;
            border: 1px solid $grey;
            padding: 0 10px;
            cursor: pointer;
            color: black;
            border-radius: 5px;

            background: linear-gradient(45deg, transparent 50%, $grey 50%),
              linear-gradient(135deg, $grey 50%, transparent 50%);
            background-position: calc(100% - 17px) calc(1em + 3px),
              calc(100% - 12.5px) calc(1em + 3px), 100% 0;
            background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
            background-repeat: no-repeat;

            &:focus {
              outline: none;
              border: 1px solid $primaryGreen;
            }
          }

          .down-arrow {
            z-index: 1;
            margin-top: 5px;
            margin-bottom: 15px;
            cursor: pointer;
          }
        }

        .max-characters {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }

      button {
        margin-left: 15%;
        font-size: 0.9rem;
        text-decoration: none;
        border: 1px solid $primaryGreen;
        border-radius: 4px;
        padding: 10px 40px;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;
        margin-top: 10px;
        outline: none;
        background: $primaryGreen;

        &:hover {
          background: $darkGreen;
          border: 1px solid $darkGreen;
        }

        @media (max-width: $secondBreak) {
          font-size: 0.9rem;
          margin-left: 0;
        }

        @media (max-width: $thirdBreak) {
          font-size: 0.8rem;
        }
      } //button

      .note {
        margin-left: 15%;
        font-size: 0.7rem;
        margin-top: 10px;

        @media (max-width: $secondBreak) {
          margin-left: 0;
        }
      }

      .submission-result {
        margin-left: 15%;
        font-size: 0.8rem;
        margin-top: 10px;
        height: 20px;
        // width: 100%;
        font-weight: bold;

        @media (max-width: $secondBreak) {
          font-size: 0.75rem;
          margin-left: 0;
        }

        p {
          width: 100%;
        }

        .error {
          display: none;
          color: red;
        }

        .error-true {
          display: block;
        }

        .success {
          display: none;
          color: $primaryGreen;
        }

        .success-true {
          display: block;
        }
      }
    } //form
  }
}

.newentry-container-dark {
  .entry-title {
    color: white;
  }

  .form-container {
    form {
      label {
        background: none;
    
        input {
          background: none;
          color: white;
        }
    
        textarea {
          background: none;
          color: white;
        }
    
        .select-container {
    
          select {
            color: white;
            background: $darkBg; // explicitely set background here to fit it on Windows, see #51.
          }
        }
      } //label

      button {
        color: black;
      }
    } //form
  }
}
