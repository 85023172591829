@import "constants.scss";

.app, .app-light {
  font-family: "Open Sans", sans-serif;
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;

  header {

    .navigation-menu {
      // box-shadow: 0 10px 40px rgba(12, 0, 68, 0.1); //gradient
      border-bottom: 1px solid lightgrey;

      display: grid;
      grid-template-columns: 10% 1fr 1fr 10%;
      padding: 15px 0;
      grid-template-areas: ". logo items .";

      // @media (max-width: $firstBreak) {
      //   grid-template-areas: ". logo items .";
      //   grid-template-columns: 10% 1fr 1fr 10%;
      // }

      @media (max-width: $secondBreak) {
        grid-template-columns: 5% 1fr 1fr 5%;
      }

      a {
        grid-area: logo;

        img {
          height: auto;
          width: 200px;

          @media (max-width: $thirdBreak) {
            width: 175px;
          }
        }
      }

      .nav-items {
        grid-area: items;
        align-self: center;
        width: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .newentry {
          font-size: 1rem;
          text-decoration: none;
          border: 1px solid $primaryGreen;
          border-radius: 4px;
          padding: 10px 20px;
          color: white;
          cursor: pointer;
          transition: all 0.2s ease;
          background: $primaryGreen;

          @media (max-width: $thirdBreak) {
            font-size: 0.8rem;
            padding: 9px 18px;
          }

          &:hover {
            background: $darkGreen;
            color: white;
            border: 1px solid $darkGreen;
          }
        }

        // .drawer-button-container {
        //   grid-area: items;
        //   align-self: center;

        //   display: flex;
        //   align-items: center;
  
        //   .drawer-button {
        //     display: flex;
        //     align-items: center;

        //     border: none;
        //     border-radius: 5px;
        //     font-size: 0.9rem;
        //     cursor: pointer;
        //     padding: 5px;
        //     transition: ease .3s all;
        //     outline: none;
  
        //     &:hover {
        //       color: $primaryGreen;
        //     }
        //   }
        // }

        img {
          height: 14px;
          width: auto;
          margin-left: 25px;

          cursor: pointer;

          &:hover {
            fill: red;
          }

          @media (max-width: $secondBreak) {
            font-size: 15px;
          }

          // @media (max-width: $secondBreak) {
          //   margin-left: 7.5%;
          // }
        }
      }
    }
  } //header

  .main-content {
    padding-bottom: 140px;
    width: 100%;
    margin: 0 auto;
  }

  footer {
    background: #003822;

    .footer-content {
      margin: 0 10%;
      padding: 60px 0 40px 0;

      // @media (max-width: $firstBreak) {
      //   margin-left: 10%;
      // }

      @media (max-width: $secondBreak) {
        margin-left: 5%;
      }

      .columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .column {
          color: white;
          display: flex;
          flex-direction: column;
          font-size: 1rem;

          h2 {
            font-weight: bold;
            margin-bottom: 30px;
          }

          a {
            color: darken(white, 20%);
            text-decoration: none;
            margin-bottom: 20px;
            transition: all 0.3s ease;

            &:hover {
              color: white;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .notes {

        p {
          color: white;
          font-size: 0.9rem;
          margin-bottom: 7.5px;
  
          @media (max-width: $thirdBreak) {
            font-size: 0.8rem;
          }
  
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    } // footer-content
  }
}

.app-dark {
  background: $darkBg;
  color: white;

  header {
    .navigation-menu {
      border-bottom: 1px solid #444444;
      
      .nav-items {
        .newentry {
          color: black;

          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .main-content {
    background: $darkBg;
  }
}