@import "../../constants.scss";

.drawer-list {
    font-family: "Open Sans", sans-serif;
    // padding-bottom: 10px;

    .drawer-title {
        padding: 20px 0px 20px 14px;
        font-size: 0.9rem;
        color: $mediumGrey;

        @media (max-width: $thirdBreak) {
            font-size: 0.8rem;
        }
    }

    a {
        text-decoration: none;
        color: black;

        .list-item {
            // width: 100%;
    
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
    
            padding: 10px 0px 10px 14px;
    
            cursor: pointer;
    
            font-size: 0.9rem;

            @media (max-width: $thirdBreak) {
                font-size: 0.8rem;
            }
    
            p {
                margin-left: 10px;
            }
    
            &:hover {
                background-color: lighten($mediumGrey, 50%);
            }
    
            .icon {
                color: $mediumGrey;
            }
        }
    }

    .nested-list-item {
        // width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        padding: 10px 0px 10px 32px;

        cursor: pointer;

        font-size: 0.9rem;

        @media (max-width: $thirdBreak) {
            font-size: 0.8rem;
        }

        p {
            margin-left: 10px;
        }

        &:last-of-type {
            padding: 10px 0px 10px 32px;
        }

        &:hover {
            background-color: lighten($mediumGrey, 50%);
        }

        .icon {
            color: $mediumGrey;
        }
    }
}

.drawer-list-dark {
    background: #444444;

    .drawer-title {
        color: white;
    }

    a {
        color: white;

        .list-item {
            .icon {
                color: white;
            }

            &:hover {
                background-color: lighten(#444444, 10%);
            }
        }

        .nested-list-item {
            .icon {
                color: white;
            }

            &:hover {
                background-color: lighten(#444444, 10%);
            }
        }
    }
}