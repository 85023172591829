.heading {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(81 177 62 / var(--tw-text-opacity));
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.supporters {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 1em 0;
  text-align: center;
}

.supporters p {
  margin-left: auto;
  margin-right: auto;
  max-width: 32em;
}

.supporters .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2em 0;
}

.supporters .logos a {
  color: var(--btcpay-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
}

.supporters .logos svg {
  max-height: 60px;

  @media (max-width: 519px) {
    max-height: 45px;
  }
}

.image-text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.hover-underline:hover {
  text-decoration-line: underline;
}

/* SVG SPRITE */
.strike {
  fill: var(--btcpay-body-text);
}
.hrf {
  fill: var(--btcpay-body-text);
}
.luna-node1 {
  fill: #004581;
  fill-opacity: 1;
  fill-rule: evenodd;
  stroke: none;
}
.luna-node2 {
  fill: #3384b9;
  fill-opacity: 1;
  fill-rule: evenodd;
  stroke: none;
}
